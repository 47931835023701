.ag-theme-bidbow {
  --ag-header-column-separator-display: block;
  --ag-odd-row-background-color: white;
  --ag-header-column-separator-height: 100%;
  --ag-header-background-color: #f4f4f6;
  --ag-header-foreground-color: #31343c;
}

.ag-header-cell {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.small-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 300px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.medium-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.expand-modal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.calculator-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 400px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.large-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  height: 75%;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.o--App--profileWidget {
  width: 100% !important;
  margin: 0 !important;
  max-width: 65% !important;
  background-color: inherit !important;
}

.spreadsheet-wrapper .spreadsheet {
  overflow: auto;
}

.diffhandsontable .add {
  background-color: #7fff7f;
}
.diffhandsontable .remove {
  background-color: #ff7f7f;
}
.diffhandsontable td.modify {
  background-color: #7f7fff;
}
.diffhandsontable td.conflict {
  background-color: red;
}
.diffhandsontable .spec {
  background-color: #aaa;
}
.diffhandsontable .move {
  background-color: #ffa;
}
.diffhandsontable .null {
  color: #888;
}
.diffhandsontable table {
  border-collapse: collapse;
}
.diffhandsontable td,
.diffhandsontable th {
  padding: 3px 7px 2px;
}
.diffhandsontable .header,
.diffhandsontable th {
  background-color: #aaf;
  font-weight: 700;
  padding-bottom: 4px;
  padding-top: 5px;
  text-align: left;
}
.diffhandsontable tr.header th {
  border-bottom: 2px solid #000;
}
.diffhandsontable .gap {
  color: #888;
}
.diffhandsontable td {
  empty-cells: show;
}
.diffhandsontable tr:first-child td {
  border-top: 1px solid #2d4068;
}
.diffhandsontable td:first-child {
  border-left: 1px solid #2d4068;
}
