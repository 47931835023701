@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app,
.content{
  height: 100%;
  width: 100%;
  font-family: 'Source Sans 3', sans-serif;
  overflow: hidden;
}

.app{
  display: flex;
  position: relative;
}

/* Scrollbar */
::-webkit-scrollbar{
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track{
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb{
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-track:hover{
  background: #555;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
